import React from 'react';
import { observer, inject } from 'mobx-react';
// import ReactDOM from 'react-dom';
import style from './style.module.css';

@inject(store => {
    const { myShopApiStore, ifaceMngr } = store;
    return { myShopApiStore, ifaceMngr };
})

@observer
class About extends React.Component {

    render() {
        return (
        <div className={style.container}>
            <div className={style.Header}>
                { (this.props.ifaceMngr.activeTopMenu == "About") ? 'אודות' : '' }
                { (this.props.ifaceMngr.activeTopMenu == "Terms") ? 'תקנון' : '' }
                { (this.props.ifaceMngr.activeTopMenu == "RetPolicy") ? (this.props.myShopApiStore.my_env.url.indexOf("falafelzikhron") >= 0 ? 'זכיינות' : 'מדיניות ביטולים') : '' }
                { (this.props.ifaceMngr.activeTopMenu == "PPolicy") ? (this.props.myShopApiStore.my_env.url.indexOf("falafelzikhron") >= 0 ? 'יצירת קשר' : 'מדיניות פרטיות') : '' }
            </div>
            <div id="about" className={style.About}>
                { (this.props.ifaceMngr.activeTopMenu == "About") ? this.props.myShopApiStore.getAbout() : '' }
                { (this.props.ifaceMngr.activeTopMenu == "Terms") ? this.props.myShopApiStore.getTerms() : '' }
                { (this.props.ifaceMngr.activeTopMenu == "RetPolicy") ? this.props.myShopApiStore.getRetPolicy() : '' }
                { (this.props.ifaceMngr.activeTopMenu == "PPolicy") ? this.props.myShopApiStore.getPPolicy() : '' }
            </div>
        </div>
        )
    }
}

export default About;