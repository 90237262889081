import React from "react";
import NumberFormat from "react-number-format";
import MaxToppingsMsg from "../MaxToppingsMsg";
import style from "./style.module.css";

const SubForm2 = ({
  currentStep,
  handleChange,
  shopToppings,
  selectedToppings,
  toppingsDescription,
  toppingsFree,
  hasError,
  maxToppings,
  toppingsAddPaid,
  closeMaxToppingMsg,
  oneToppingMode,
  mainColor
}) => {
  const curr = "₪";

  if (currentStep !== 2) {
    return null;
  }

  const selectedToppingAmount = (id) => { 
    return selectedToppings
              .filter(topping => topping.id == id)
              .reduce((acc, top)=>acc + (top.id == id)?top.shopToppingAmount:0, 0);
  }

  const selectedToppingSum = (id) => {
    let sum = selectedToppings.filter((item)=>item.id == id && item.sum>0)[0]?.sum;
    return sum 
            ? <NumberFormat value={sum} prefix={curr} decimalScale={2} fixedDecimalScale={true} displayType={"text"}/>
            : 'ללא עלות';
  }

  return (
    <div className={style.Container}>
      <div className={style.ToppingsDescription}>{toppingsDescription}</div>
      <div className={style.ItemToppingList}>
      { hasError && <MaxToppingsMsg closeMaxToppingMsg={closeMaxToppingMsg}
                          limitedToppingsNumber={(toppingsAddPaid===0 && maxToppings > toppingsFree)
                                              || maxToppings === null || maxToppings === 0 ? toppingsFree : maxToppings}/> }
      {shopToppings.map((st, idx) => {
          return (
            <div key={idx} className={style.ItemTopping}>
              <div className={style.WrapBtnAndName}>
                <input
                  type="checkbox"
                  value={st.id}
                  name="selectedToppings"
                  checked={selectedToppings.some(topping => topping.id == st.id)}
                  onChange={handleChange}
                />
                <span className={style.ItemToppingName} /*style={{whiteSpace: 'pre-wrap', overflowWrap: 'break-word'}}*/>{st.topping.name}</span>
              </div>
              <span className={style.ItemToppingPrice} /*style={{position:'absolute', left: 0, right:0, textAlign: 'center'}}*/>
                       {selectedToppingSum(st.id)}
              </span>
              {/* {console.log(oneToppingMode)} */}
              { oneToppingMode === 0
                ? <div className={style.btnContainer}>
                  <div className={style.InputWithCounter} style={{backgroundColor: mainColor}}>
                    <div type="button" name="selectedToppings" className={style.buttonPlus} value={st.id} onClick={(e)=>{handleChange(e, {className:'buttonPlus', name:'selectedToppings', type:'', value: st.id})}}>+</div>
                    <div name="countTopping" className={style.ItemAmount}>{selectedToppingAmount(st.id)}</div>
                    <div name="selectedToppings" className={style.buttonMinus} value={st.id} onClick={(e)=>handleChange(e, {className:'buttonMinus', name:'selectedToppings', type:'', value: st.id})}>-</div>
                  </div>
                  </div>
                :null
              } 
              
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SubForm2;
