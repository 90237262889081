import React from "react";
import { toJS } from "mobx";
import { observer, inject } from "mobx-react";
import style from "./style.module.css";
import CartItem from "./CartItem";
import NumberFormat from "react-number-format";
import ItemUpdateForm from "./ItemUpdateForm/index";
import PackItemUpdateForm from "./PackItemUpdateForm";
import EditCartPackForm from "./EditCartPackForm";
import PaymentButtons from "./PaymentButtons";
import blackBasket from 'assets/basket/blackBasket.png'
import redBasket from 'assets/basket/redBasket.png'
import blueBasket from 'assets/basket/blueBasket.png'
import greenBasket from 'assets/basket/greenBasket.png'
import yellowBasket from 'assets/basket/yellowBasket.png'
import blackCart from 'assets/cart/blackCart.png'
import redCart from 'assets/cart/redCart.png'
import blueCart from 'assets/cart/blueCart.png'
import greenCart from 'assets/cart/greenCart.png'
import yellowCart from 'assets/cart/yellowCart.png'
import ProductsAssociatedModal from "../../ProductsAssociatedModal";

@inject((store) => {
  const { myShopApiStore } = store;
  return { myShopApiStore };
})

@observer
class Basket extends React.Component {

  constructor(props) {
    super(props)
    this.scrollUpRef = React.createRef();

    this.state = {
      isMobile: window.innerWidth <= 850,
      productsAssociated: null,
      productsAssociatedModalIsOpen: false,
      isBasketOpened: true
    }
  }

  updateIsMobile = () => {
    this.setState({ isMobile: window.innerWidth <= 850 });
  }

  componentDidMount = () => {
    this.scrollUpRef.current && this.scrollUpRef.current.scrollIntoView({behavior: 'smooth'})

    window.addEventListener('resize', this.updateIsMobile); 
  }

  componentDidUpdate() {
    this.scrollUpRef.current && this.scrollUpRef.current.scrollIntoView({behavior: 'smooth'})
  }

  componentWillUnmount() {
    // console.log('Basket > componentWillUnmlount')
    window.removeEventListener('resize', this.updateIsMobile);

    this.props.basketIsOpen && this.props.closeBasket(); //mobile - goes back to menu (and not to basket)!
    
  }
  
  handleCheckboxChange = (event, id) => {
    const { getCart, calcTotalCartPrice } = this.props.myShopApiStore;
    
    getCart().cartItemList.forEach(cartItem => {
      if(cartItem.id === id){
        cartItem.isReadyToOrder = event.target.checked;
      }
    })

    calcTotalCartPrice();
  }

  getCartIcon = () => {
    const { mainColor } = this.props.myShopApiStore;
    switch(mainColor){
      case '#EB0028':
        return redCart
      case '#1BB449':
        return greenCart
      case '#00B1E1':
        return blueCart
      case '#F1A103':
        return yellowCart
      default:
        return blackCart
    }
  }
  getBasketIcon = () => {
    const { mainColor } = this.props.myShopApiStore;
    switch(mainColor){
      case '#EB0028':
        return redBasket
      case '#1BB449':
        return greenBasket
      case '#00B1E1':
        return blueBasket
      case '#F1A103':
        return yellowBasket
      default:
        return blackBasket
    }
  }

  openProductsAssociatedModal = () => {
    this.setState({productsAssociatedModalIsOpen: true});

    if(!this.props.basketIsOpen) {
      this.props.fastToggleBasket();
    }
  }

  closeProductsAssociatedModal = () => {
    this.setState({productsAssociatedModalIsOpen: false});

    if(!this.state.isBasketOpened) {
      this.props.fastToggleBasket();
    }
  }

  checkForProductsAssociated = async (item, isAdded = false) => {
    const { addToCart, isPackItemUpdating,
      isCartItemUpdating } = this.props.myShopApiStore;
    // const item = itemAssociated ? itemAssociated : this.props.item;

    const unitTypes = item.unitTypes ? item.unitTypes : null;
    let isDuringOrder = isPackItemUpdating || isCartItemUpdating;

    if(!isDuringOrder || !isAdded){
      addToCart(item, unitTypes?.length ? unitTypes[0] : null)
      return;
    }
  }

  render() {
    const { getCart,
            cartPrice,
            isCartItemUpdating,
            isEditCartPackForm,
            cartItemUpdating, mainColor, shop } = this.props.myShopApiStore;
    
    let itemsInCart = 0;
    getCart().cartItemList.forEach(cartItem => {cartItem.isReadyToOrder && itemsInCart++})
    
    const curr = "₪";
    const isCartPackItem = cartItemUpdating && (cartItemUpdating.item.product.levels ? cartItemUpdating.item.product.levels.length : false);
    return (
      <div className={style.Container}>
        <div className={style.TopDiv} style={{height: isCartItemUpdating ? '100%' : '80%'}}>
          {
            !this.state.isMobile && 
              <div className={style.Summary} style={{ borderColor: this.props.myShopApiStore.mainColor }}>
                <div className={style.CartWrapper}>
                  <span
                    className={style.ItemsInCart}
                    style={{ backgroundColor: this.props.myShopApiStore.mainColor }}
                  >
                    {itemsInCart}
                  </span>
                  <img className={style.CartImg} src={this.getCartIcon()} alt="cart" />
                </div>
                {
                  shop.isBusiness
                  ?
                    ''
                  :
                    <div className={style.CartSum} style={{ color: this.props.myShopApiStore.mainColor }} >
                      <span className={style.CartSumSh}>{curr}</span>
                      <span className={style.CartSumVal}>
                        <NumberFormat
                          value={cartPrice}
                          // prefix={curr}
                          decimalScale={2}
                          fixedDecimalScale={true}
                          displayType={"text"}
                        />
                      </span>
                    </div>
                }
                {
                  shop.isBusiness
                  ?
                    ''
                  :
                    <div className={style.CartText} style={{ color: this.props.myShopApiStore.mainColor }}>
                      סה"כ
                      <br />
                      לתשלום
                    </div>
                }
              </div>
          }
          
          {isCartItemUpdating 
          ? (
            isCartPackItem ? (isEditCartPackForm ? <EditCartPackForm /> : <PackItemUpdateForm checkForProductsAssociated={this.checkForProductsAssociated}/>) : <ItemUpdateForm checkForProductsAssociated={this.checkForProductsAssociated}/>
          ) 
          : (
            <div className={style.CartItems}>
            {
              (getCart().cartItemList.length) 
              ? (
                  getCart().cartItemList.map((cartItem, idx) => {
                    return (
                      <div key={`${cartItem.item.id}_${idx}`} ref={this.scrollUpRef}>
                        <CartItem cartItem={cartItem}
                                  index={idx} 
                                  handleCheckboxChange={this.handleCheckboxChange}
                        />
                      </div>
                    )
                  })
                )
              : (
                <div className={style.CartItemsEmpty} style={{color: mainColor, zIndex: 0}} onClick={this.state.isMobile ? this.props.closeBasket : null}>
                  <span>הסל שלך ריק</span>
                  <span>הוסף מוצרים לסל</span>
                  <img className={style.BasketImg} src={this.getBasketIcon()} alt="basket" />
                </div>
              )}
          </div>
          )}
          {/*<div className={style.CartButtons}>buttons</div>*/}
        </div>
        <div className={style.PaymentButtons}>
          <PaymentButtons hasProducts={getCart().cartItemList.some(cartItem => cartItem.isReadyToOrder) && !isCartItemUpdating} />
        </div>
      </div>
      );
  }
}

export default Basket;
