import React from "react";
import { observer, inject } from "mobx-react";
import style from "./style.module.css";
import MinOrderCostModal from "./MinOrderCostModal";
import ChangeAddressModal from './ChangeAddressModal'
import MakeOrderErrorModal from "../MakeOrderErrorModal";
import APILoader from "../../APILoader";

@inject((store) => {
  const { myShopApiStore, ifaceMngr } = store;
  return { myShopApiStore, ifaceMngr };
})

@observer
class ShippingOptionForm extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            errors: [],
            minOrderCostModalIsOpen: false,
            changeAddressModalIsOpen: false,
            makeOrderErrorModalIsOpen: false,
            makeOrderErrorModalErrorCode: '',
            makeOrderErrorModalErrorMessage: '',
            apiLoaderIsOpen: false,
            selectedTimeIdx: ''
        }
    }
    optionBtnsStyle = (orderType) => {
        const { orderData, mainColor } = this.props.myShopApiStore;
        if(orderData.orderType == orderType){
            return{
                backgroundColor: mainColor,
                color: "#FFFF" 
            }
        } else {
            return{
                backgroundColor: "#eeeeee",
                color: "#9b9a9a" 
            }
        }
    }
    
    componentDidMount = async () => {
        this.setState({
            errors: []
        });
    }

    componentDidMount = () => {
        const { isDelivery } = this.props.myShopApiStore.orderData;
        this.props.setShippingOption(isDelivery);
    }
    componentDidUpdate() {
        const { isDelivery } = this.props.myShopApiStore.orderData;
        this.props.setShippingOption(isDelivery);
    }

    closeMinOrderCostModal = () => {
        this.setState({minOrderCostModalIsOpen: false})
    }

    openMinOrderCostModal = () => {
        this.setState({minOrderCostModalIsOpen: true})
    }

    closeChangeAddressModal = () => {
        this.setState({changeAddressModalIsOpen: false})
    }

    openChangeAddressModal = () => {
        this.setState({changeAddressModalIsOpen: true})
    }

    handleNextSubmit = async (e) => {
        e.preventDefault();
        const { orderData, setDeliveryCost,
                calcTotalCartPrice, shop,
                cartPrice, userToken, userParams,
                checkTime } = this.props.myShopApiStore;

        const errors = [];
        if(orderData.isDelivery){
            if(orderData.deliveryData.date === '' && !shop.withoutFuture_delivery){
                errors.push('deliveryTime')
            }
        }
        else{
            if(orderData.pickupData.date === '' && !shop.withoutFuture_pickup){
                errors.push('pickupTime')
            }
        }

        if(!errors.length){

            this.openAPILoader()
            const allowed_time = await checkTime();
            this.closeAPILoader()

            if(allowed_time.errorCode == '0') {
                //reset delivery cost when user pickup order
                if(!orderData.isDelivery){
                    setDeliveryCost(0);
                    calcTotalCartPrice();

                    this.props.nextStep(); 
                }
                else{ //checks minimal cost to order (only for delivery order)
                    if(shop.minimalOrder > cartPrice){
                        this.openMinOrderCostModal();
                    }
                    else{
                        // if(userToken && userParams.city){
                        //     this.openChangeAddressModal();
                        // }
                        // else{
                            this.props.nextStep();
                        // }
                    }
                }
            } else {
                this.setMakeOrderErrorModalParams(allowed_time.errorCode, allowed_time.errorMessage);
                this.openMakeOrderErrorModal();
            }
        }
        else{
            this.setState({
                errors
            })
        }
    }

    closeMakeOrderErrorModal = () => {
        this.setState({makeOrderErrorModalIsOpen: false})
    }

    openMakeOrderErrorModal = () => {
        this.setState({makeOrderErrorModalIsOpen: true})
    }

    setMakeOrderErrorModalParams = (makeOrderErrorModalErrorCode, makeOrderErrorModalErrorMessage) => {
        this.setState({
            makeOrderErrorModalErrorCode: makeOrderErrorModalErrorCode,
            makeOrderErrorModalErrorMessage: makeOrderErrorModalErrorMessage
        })
    }

    openAPILoader = () => {
        this.setState({apiLoaderIsOpen: true})
    }

    closeAPILoader = () => {
        this.setState({apiLoaderIsOpen: false})
    }

    getFormatDate = date => {
        const myDate = new Date(date * 1000);
        const myFormatedDate = myDate.toLocaleDateString();

        return myFormatedDate;
    }

    getFormatTime = (date=null, from ,to) => {
        const { orderData } = this.props.myShopApiStore;
        const { isDelivery, deliveryData, pickupData } = orderData;
        
        if(date){
            return `${this.getFormatDate(date)} ${from}-${to}`
        }
        if(isDelivery){
            return `${this.getFormatDate(deliveryData.date)} ${deliveryData.from}-${deliveryData.to}`
        }
        return `${this.getFormatDate(pickupData.date)} ${pickupData.from}-${pickupData.to}`
    }

    canOrderedNow = () => {
        const { shop, orderData } = this.props.myShopApiStore;

        const days = {
            1: 'sunday',
            2: 'monday',
            3: 'tuesday',
            4: 'wednesday',
            5: 'thursday',
            6: 'friday',
            7: 'saturday'
        }
        
        const currDate = new Date();
        
        const currIntDay = currDate.getDay();
        const currHour = currDate.getHours();
        const currMin = currDate.getMinutes();

        // const times = orderData.isDelivery ? shop.deliveryTimes : shop.pickupTimes;
        const times = shop.workingTimes;

        for(let i=0 ; i<times.length ; i++){
            const strDay = times.weekday;
            const from = times.from;
            const to = times.to;

            const intHHFrom = parseInt(from.slice(0, 2));
            const intMMFrom = parseInt(from.slice(-2));
            const intHHTo = parseInt(to.slice(0, 2));
            const intMMTo = parseInt(to.slice(-2));
            
            if(days[currIntDay] === strDay){
                if(currHour === intHHFrom){
                    if(currMin >= intMMFrom){
                        if(currHour === intHHTo){
                            if(currMin <= intMMTo){
                                return true;
                            }
                        }
                        else if(currHour < intHHTo){
                            return true;
                        }
                    }
                }
                else if(currHour > intHHFrom){
                    if(currHour === intHHTo){
                        if(currMin <= intMMTo){
                            return true;
                        }
                    }
                    else if(currHour < intHHTo){
                        return true;
                    }
                }
            }
        }

        return false;
    }

    cleanTime = () => {
        this.setState({
            selectedTimeIdx: ''
        });
    }

    handleChange = (event) => {
        const { name, value } = event.target;  /// value===idx
        const { shop, setOrderData, orderData } = this.props.myShopApiStore;
        let date = '';
        let from = '';
        let to = '';
        
        const {deliveryData, pickupData, isDelivery} = orderData;

        if(isDelivery){
            if(name === "deliveryTime"){
                this.setState({
                    selectedTimeIdx: value
                });

                shop.deliveryTimes.forEach((dt, idx) => {
                    if(parseInt(value) === idx){
                        date = dt.date;
                        from = dt.from;
                        to = dt.to;
                    }
                })
                setOrderData('deliveryData', {...deliveryData, date, from, to})
            }
            else{
                setOrderData('deliveryData', {...deliveryData, [name]: value})
            }

            if(name === 'branch'){
                setOrderData('branch', value)
            }
        }
        else{
            if(name === "pickupTime"){
                this.setState({
                    selectedTimeIdx: value
                });

                shop.pickupTimes.forEach((pt, idx) => {
                    if(parseInt(value) === idx){
                        date = pt.date;
                        from = pt.from;
                        to = pt.to;
                    }
                })
                setOrderData('pickupData', {...pickupData, date, from, to})
            }
            else{
                setOrderData('pickupData', {...pickupData, [name]: value})
            }

            if(name === 'branch'){
                setOrderData('branch', value)
            }
        }

        let errors = this.state.errors;
        const eIndex = errors.indexOf(name);
        if (eIndex > -1) {
            errors.splice(eIndex, 1);
            this.setState({
                errors: errors,
            });
        }
    }

    render() {
        if(this.props.currentStep !== 1){
            return null;
        } 

        const { shop, orderData, setOrderData, mainColor, resetOrderData } = this.props.myShopApiStore;
        const { branches, orderTypes } = shop;
        const { isDelivery, deliveryData, pickupData } = orderData;

        const hasDeliveryOp = orderTypes ? orderTypes.some(ot => ot === 'delivery') : true;
        const hasPickupOp = orderTypes ? orderTypes.some(ot => ot === 'pickup') : true;
        const hasSitOp = orderTypes ? orderTypes.some(ot => ot === 'sit') : true;
        
        return (
            <div className={style.Container}>
                <div className={style.Title}>
                    <div>דאגנו שיהיה לך הכי נוח שאפשר!</div>
                    <div>איך תרצה לקבל את ההזמנה שלך?</div>
                </div>
                <div className={style.UserChoosing}>
                    <div className={style.OptionBtns}>
                        {hasDeliveryOp && <button onClick={() => {this.cleanTime(); resetOrderData(); setOrderData("isDelivery", true); setOrderData("orderType", 'delivery');}} style={this.optionBtnsStyle('delivery')}>משלוח</button>}
                        {hasPickupOp && <button onClick={() => {this.cleanTime(); resetOrderData(); setOrderData("isDelivery", false); setOrderData("orderType", 'pickup');}} style={this.optionBtnsStyle('pickup')}>איסוף עצמי</button>}
                        {hasSitOp && <button onClick={() => {this.cleanTime(); resetOrderData(); setOrderData("isDelivery", false); setOrderData("orderType", 'sit');}} style={this.optionBtnsStyle('sit')}>ישיבה במקום</button>}
                    </div>
                    {
                        isDelivery
                        ?
                            hasDeliveryOp
                            ?
                                <form onSubmit={this.handleNextSubmit}>
                                    <div>
                                        {
                                            (branches ? branches.length : false)
                                            ?  
                                                <select name="branch" onChange={this.handleChange} style={{border: this.state.errors.includes('branches') ? '1px solid #FF0000' : 'none'}}>
                                                    <option defaultValue="selected">מאיזה סניף תרצה להזמין?</option>
                                                    {shop.branches.map((branch, idx) => {
                                                        return(
                                                                <option value={branch.name} key={idx}>
                                                                    {branch.name}
                                                                </option>
                                                        )
                                                    })}
                                                </select>
                                            : 
                                                null
                                        }
                                        
                                        {
                                            !shop.withoutFuture_delivery
                                            ?
                                                <div>
                                                    <select name="deliveryTime" value={this.state.selectedTimeIdx} onChange={this.handleChange} style={{border: this.state.errors.includes('deliveryTime') ? '1px solid #FF0000' : 'none'}}>
                                                        <option value="">בחר תאריך למשלוח</option>
                                                        {shop.deliveryTimes.map((dt, idx) => {
                                                            return(
                                                                <option value={idx} key={idx}>
                                                                    {this.getFormatTime(dt.date, dt.from, dt.to)}
                                                                </option>
                                                            )
                                                        })}
                                                    </select>
                                                </div>
                                            :
                                                ''
                                        }
                                        <div className={style.Commands}>
                                            <textarea name="orderComment" value={deliveryData.orderComment} onChange={this.handleChange} placeholder="הוסף הערה להזמנה"/>
                                            <textarea name="deliveryGuyComment" value={deliveryData.deliveryGuyComment} onChange={this.handleChange} placeholder="הוסף הערה לשליח"/>
                                        </div>
                                    </div>
                                    <div className={style.Btns}>
                                        <button type="submit" style={{backgroundColor: mainColor}}>המשך</button>
                                        <button type="button" onClick={() => this.props.ifaceMngr.setActiveTopMenu("ShopMenu")}>חזור לתפריט</button>
                                    </div>
                                </form>
                            :
                                ''
                        :
                            hasPickupOp || hasSitOp
                            ?
                                <form onSubmit={this.handleNextSubmit}>
                                    <div>
                                    {
                                        (branches ? branches.length : false)
                                        ? 
                                            <div>
                                                <select name="branch" onChange={this.handleChange} style={{border: this.state.errors.includes('branches') ? '1px solid #FF0000' : 'none'}}>
                                                    <option defaultValue="selected">מאיזה סניף תרצה לאסוף?</option>
                                                    {shop.branches.map((branch, idx) => {
                                                        return(
                                                                <option value={branch.name} key={idx}>
                                                                    {branch.name}
                                                                </option>
                                                        )
                                                    })}
                                                </select>
                                            </div>
                                            : 
                                                null
                                    }
                                    
                                        {
                                            !shop.withoutFuture_pickup 
                                            ?
                                                <select name="pickupTime" value={this.state.selectedTimeIdx} onChange={this.handleChange} style={{border: this.state.errors.includes('pickupTime') ? '1px solid #FF0000' : 'none'}}>
                                                    <option value="">בחר תאריך ל{
                                                        orderData.orderType === 'pickup'
                                                        ?
                                                            "איסוף"
                                                        :
                                                            "ישיבה"
                                                    }</option>
                                                    {shop.pickupTimes.map((pt, idx) => {
                                                        return(
                                                                <option value={idx} key={idx}>
                                                                    {this.getFormatTime(pt.date, pt.from, pt.to)}
                                                                </option>
                                                        )
                                                    })}
                                                </select>
                                            :
                                                ''
                                        }
                                        <div className={style.Commands}>
                                            <textarea name="orderComment" value={pickupData.orderComment} onChange={this.handleChange} placeholder="הוסף הערה להזמנה"/>
                                        </div>
                                    </div>
                                    <div className={style.Btns}>
                                        <button type="submit" style={{backgroundColor: mainColor}}>המשך</button>
                                        <button type="button" onClick={() => this.props.ifaceMngr.setActiveTopMenu("ShopMenu")}>חזור לתפריט</button>
                                    </div>
                                </form>
                            :
                                ''
                    }
                </div>
                <MinOrderCostModal closeModal={this.closeMinOrderCostModal} modalIsOpen={this.state.minOrderCostModalIsOpen} />
                <ChangeAddressModal closeModal={this.closeChangeAddressModal}
                                    modalIsOpen={this.state.changeAddressModalIsOpen}
                                    nextStep={this.props.nextStep}
                />
                <APILoader modalIsOpen={this.state.apiLoaderIsOpen} />
                <MakeOrderErrorModal 
                    modalGoBack={false}
                    closeModal={this.closeMakeOrderErrorModal} 
                    modalIsOpen={this.state.makeOrderErrorModalIsOpen} 
                    makeOrderErrorModalErrorCode={this.state.makeOrderErrorModalErrorCode} 
                    makeOrderErrorModalErrorMessage={this.state.makeOrderErrorModalErrorMessage}
                />
            </div>
        )
    }
}

export default ShippingOptionForm
