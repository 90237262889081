import React from "react";
import NumberFormat from "react-number-format";
import ReactTooltip from 'react-tooltip';
import { observer, inject } from "mobx-react";
import style from "./style.module.css";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import CarouselModal from "../../ItemList/Item/CarouselModal";

@inject((store) => {
  const { myShopApiStore } = store;
  return { myShopApiStore };
})
@observer
class CartItem extends React.Component {
  constructor(props) {
    super(props)
    
    this.inputRef = React.createRef();
    this.state = {
       comment: '',
       carouselModalIsOpen: false
    }
  }

  handleCommentChange = () => {
    if(this.inputRef.current){
      this.props.cartItem.comment = this.inputRef.current.value;
    }
  }

  openCarouselModal = () => {
    this.setState({carouselModalIsOpen: true});
  }

  closeCarouselModal = () => {
      this.setState({carouselModalIsOpen: false});
  }

  render() {
    const { removeCartItem, openCartItemForm,
            getItemPrice, getCartPackItemPrice,
            mainColor, shop } = this.props.myShopApiStore;
    const { cartItem, index, handleCheckboxChange } = this.props;
    const product = cartItem.item.product ? cartItem.item.product : null;
    const curr = "₪";

    const iNew = <div className={`${style.iFlags} ${style.iNew}`}>חדש!</div>;
    const iSale = <div className={`${style.iFlags} ${style.iSale}`}>במבצע!</div>;
    const iSeason = <div className={`${style.iFlags} ${style.iSeason}`}>עונה!</div>;

    const hasProductOption = cartItem.item.shopProductOptions ? cartItem.item.shopProductOptions.length : false;
    const isEditableItem = hasProductOption || cartItem.item.shopToppings.length || cartItem.item.shopBaseToppings?.length; 
    const isCartPackItem = cartItem.item.product.productType === "pack";
    const { imageBig } = cartItem.item.product;
    
    return (
      <div className={style.Container}>
        {
          imageBig && imageBig.length
          ?
            <CarouselModal modalIsOpen={this.state.carouselModalIsOpen} closeModal={this.closeCarouselModal} item={cartItem.item}/>
          :
            ''
        }
        <div className={style.RightElements}>
            <div className={style.ItemCheckbox}>
              <input
                    type="checkbox"
                    checked={cartItem.isReadyToOrder}
                    onChange={(e) => handleCheckboxChange(e, cartItem.id)}
              />
            </div>
        </div>
        <div className={style.MidElements}>
          <div className={style.MidUpElements}>
            <div className={style.MidRightElements}>
              <div className={style.ImagFlagDetalisWrapper}>
                <div className={style.ImageAndFlag}>
                  <LazyLoadImage
                    effect='blur'
                    className={style.ItemImage}
                    src={product ? product.image : ""}
                    alt={product ? product.description : ""}
                    onClick={this.openCarouselModal}
                    style={{cursor: "pointer"}}
                  />
                  <div className={style.ItemFlags}>
                    { cartItem.item.isNew ? iNew : '' }
                    { cartItem.item.isSale ? iSale : '' }
                    { cartItem.item.isSeason ? iSeason : '' }
                  </div>
                </div>
                <div className={style.ItemDetails}>
                  <div className={style.ItemName} title={cartItem.item.product.name} style={{color: mainColor}}>{cartItem.item.product.name}</div>
                  <div className={style.ItemPrice}>
                  {
                    shop.isBusiness
                    ?
                      ""
                    :
                    <NumberFormat
                      value={isCartPackItem ? getCartPackItemPrice(cartItem) : getItemPrice(cartItem)}
                      prefix={curr}
                      decimalScale={2}
                      fixedDecimalScale={true}
                      displayType={"text"}
                    />
                  }
                  </div>
                  <div className={style.ItemQuantity}>
                    {
                      cartItem.unitType
                      ? 
                        cartItem.unitType.type === "kg"
                        ? 
                          `${cartItem.quantity} ק"ג`
                        :
                          `${cartItem.quantity} יח'`
                      :
                        cartItem.item.unitTypes[0].type === "kg"
                        ? 
                          `${cartItem.quantity} ק"ג`
                        :
                          `${cartItem.quantity} יח'`
                    }
                  </div>
                </div>
              </div>
            </div>
            <div className={style.MidLeftElements}>
              <div className={style.MoreInfo}>
                <div className={style.MoreInfoTooltip} style={{color: mainColor}}>
                  {cartItem.item.product.description && 
                      <>
                        <div className={style.label} data-tip={cartItem.item.product.description} data-for={`moreInfo_${cartItem.id}`}>
                          מידע נוסף
                        </div>
                        <ReactTooltip 
                          className={style.Tooltip}
                          place="right" 
                          multiline={true}
                          data-html={true}
                          insecure={true} 
                          id={`moreInfo_${cartItem.id}`} 
                          type='light' 
                          border={true} 
                          effect='solid' 
                        />
                      </>
                  }
                </div>
              </div>
              <div className={style.Buttons}>
                <div className={style.EditButton}>
                  {(isEditableItem || isCartPackItem) ? (
                    <button style={{backgroundColor: mainColor, border: `1px solid ${mainColor}`}} onClick={() => openCartItemForm(cartItem, isCartPackItem, true)}>
                      עדכן מוצר
                    </button>
                  ) : null}
                </div>
                <div className={style.RemoveButton}>
                  <button style={{backgroundColor: mainColor, border: `1px solid ${mainColor}`}} onClick={() => removeCartItem(index)}>
                    הסר מהסל
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className={style.Comment}>
                <div className={style.Title} onClick={() => {this.inputRef.current && this.inputRef.current.focus()}}>
                  הוסף הערה:
                </div>
                <div className={style.InputText}>
                  <input type='text' value={cartItem.comment} ref={this.inputRef} onChange={this.handleCommentChange}/>
                </div>
          </div>
        </div>
      </div>
    );
  }
}
export default CartItem;
