import React, { Component } from "react";
import style from "./style.module.css";
import { observer, inject } from "mobx-react";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import bigappslogo from '../../../assets/bigappslogo.png'
import apple from '../../../assets/apple.png'
import android from '../../../assets/android.png'


const menuBtns = [
    { name: 'PPolicy', label: 'מדיניות פרטיות' },
    { name: 'RetPolicy', label: 'מדיניות ביטולים' },
    { name: 'Terms', label: 'תקנון' },
    { name: 'Contacts', label: 'יצירת קשר' },
];

const menuBtnsFalafelZikhron = [
  { name: 'PPolicy', label: 'יצירת קשר' },
  { name: 'RetPolicy', label: 'זכיינות' },
  { name: 'Terms', label: 'תקנון' },
  { name: 'Contacts', label: 'שעות פעילות הסניף' },
];

@inject((store) => {
  const { ifaceMngr, myShopApiStore } = store;
  return { ifaceMngr, myShopApiStore };
})
@observer
class Footer extends Component {
  constructor(props) {
    super(props);
  
    this.state = {
      modalIsOpen: true, //welcome modal
      carouselModalIsOpen: true,
      isMobile: window.innerWidth <= 850,
      basketIsOpen: false,
    };
  }

  render() {

    const { setActiveTopMenu, activeTopMenu } = this.props.ifaceMngr
    const { userId, shop, setSearchValue, my_env } = this.props.myShopApiStore;
    return (
      <div className={style.Container}>
        <div className={style.MenuBtns}>
        {
            (my_env.url.indexOf("falafelzikhron") >= 0 ? menuBtnsFalafelZikhron : menuBtns).map((item, index) => {
                return (
                    <React.Fragment key={index}>
                        {item.name === 'History' && userId === null 
                        ?
                            null
                        :
                            <div key={`ind_${index}`} onClick={() => setSearchValue('', true)}>
                                <div className={activeTopMenu == item.name ?
                                    `${style.menubtn} ${style.menubtnActive}` :
                                    style.menubtn} onClick={() => setActiveTopMenu(item.name)} >{item.label}</div>
                            </div>
                        }
                    </React.Fragment>
                )
            })
        }
        </div>
        <div className={style.BigappsLogo} onClick={()=> window.open("https://my-shop.co.il/", "_blank")}>
            <LazyLoadImage
              src={bigappslogo}
              alt="my-shop"
              width="100%"
            />
        </div>
        <div className={style.MobileBtns}>
          {
            shop.linkGoogle
            ?
              <LazyLoadImage
                src={android}
                alt="android"
                width="100%"
                onClick={()=> window.open(shop.linkGoogle, "_blank")}
              />
            :
              ''
          }
          {
            shop.linkApple
            ?
              <LazyLoadImage
                src={apple}
                alt="apple"
                width="100%"
                onClick={()=> window.open(shop.linkApple, "_blank")}
              />
            :
              ''
          }
        </div>
      </div>
    )
  }
}
export default Footer;
